/* tslint:disable */
import { Injectable } from "@angular/core";

/**
 * Global configuration for Api services
 */
@Injectable({
  providedIn: "root",
})
export class ApiConfiguration {
  // local
  // baseUrl2 ='http://192.168.1.136/zhub_centuryply/centuryply_hubadmin_api/web/v1';

  //STAGING
  // baseUrl2 = 'https://hubadminapi.centuryply.bigcityvoucher.co.in/v1';
  // imageUrl = 'https://hubadminapi.centuryply.bigcityvoucher.co.in/v1';

  //UAT
  baseUrl2 = 'https://hubadminapi.centuryplyuat.bigcityvoucher.co.in/v1';
  imageUrl = 'https://hubadminapi.centuryplyuat.bigcityvoucher.co.in/v1';

  //prod new
  // baseUrl2 = 'https://hubadminapi.centuryply.bigcitydays.in/v1';
  // imageUrl = 'https://hubadminapi.centuryply.bigcitydays.in/v1';


  login = this.baseUrl2 + "/login/login";
  bsmLogin = this.baseUrl2 + '/login/bsm-login'
  bsmOtp = this.baseUrl2 + '/login/otp-verification'
  bsmResendotp = this.baseUrl2 + '/login/resendotp';

  //hubadmin
  getPrivileges = this.baseUrl2 + "/reward/getprivileges";
  getOffers = this.baseUrl2 + "/reward/getoffers";
  importPrivileges = this.baseUrl2 + "/reward/import-privileges";
  importOffers = this.baseUrl2 + "/reward/import-offers";
  importVoucher = this.baseUrl2 + "/reward/import-vouchers";
  getCategories = this.baseUrl2 + "/reward/getcategories"
  getQuaters = this.baseUrl2 + "/reward/getquarters";
  downloadPrivilleges = this.baseUrl2 + "/reward/download-privileges";
  downloadOffers = this.baseUrl2 + "/reward/download-offers";
  //brandhub
  getBrandhub = this.baseUrl2 + "/brand-hub/get-brand-hubs"
  getBrandhubTypes = this.baseUrl2 + "/brand-hub/get-brand-hub-types";
  createBrandhub = this.baseUrl2 + "/brand-hub/create-brand-hub";
  updateBrandhub = this.baseUrl2 + "/brand-hub/update-brand-hub";
  deleteBrandhub = this.baseUrl2 + "/brand-hub/delete-brand-hub";
  //banner
  createBanner = this.baseUrl2 + "/banner/create-banner";
  updateBanner = this.baseUrl2 + "/banner/update-banner";
  deleteBanner = this.baseUrl2 + "/banner/delete-banner";
  getBannerType = this.baseUrl2 + "/banner/get-banner-types";
  getBanner = this.baseUrl2 + "/banner/get-banners";
  //quarters
  getquarters = this.baseUrl2 + "/quarters/get-quarters";
  createQuarters = this.baseUrl2 + "/quarters/create-quarter";
  updateQuarters = this.baseUrl2 + "/quarters/update-quarter";
  deleteQuarters = this.baseUrl2 + "/quarters/delete-quarter";
  //engagement
  getengaagements = this.baseUrl2 + "/engagement/get-engagements";
  createEngagement = this.baseUrl2 + "/engagement/create-engagement";
  updateEngagement = this.baseUrl2 + "/engagement/update-engagement";
  deleteEngagement = this.baseUrl2 + "/engagement/delete-engagement";
  // branch
  getBranch = this.baseUrl2 + "/branch/filter-branch";
  createBranch = this.baseUrl2 + "/branch/create-branch"
  updateBranch = this.baseUrl2 + "/branch/update-branch"
  deleteBranch = this.baseUrl2 + "/branch/delete-branch"
  importBranch = this.baseUrl2 + "/branch/import-branch"

  //cotegory
  getCategory = this.baseUrl2 + "/privilege-category/get-privilege-category"
  createCategory = this.baseUrl2 + "/privilege-category/create-privilege-category"
  updateCategory = this.baseUrl2 + "/privilege-category/update-privilege-category"
  deleteCategory = this.baseUrl2 + "/privilege-category/delete-privilege-category"
  importCategory = this.baseUrl2 + "/privilege-category/import-privilege-category"

  //deco-dhamaka
  getdecoDhamakaList = this.baseUrl2 + "/queries/get-deco?program_id=1"
  cretaeDecoDhamaka = this.baseUrl2 + "/queries/create-deco?program_id=1";
  updateDecoDhamaka = this.baseUrl2 + "/queries/update-deco?program_id=1";
  deleteDecoDhamaka = this.baseUrl2 + "/queries/delete-deco?program_id=1";



  //states
  configGetStates = this.baseUrl2 + "/state/get-state?program_id=1";
  configAddState = this.baseUrl2 + "/state/create-state";
  configEditState = this.baseUrl2 + "/state/update-state";
  configDeleteState = this.baseUrl2 + "/state/delete-state";
  importExcelState = this.baseUrl2 + "/state/import-state";

  //Cities
  configGetCities = this.baseUrl2 + "/city/get-city?program_id=1";
  configAddCity = this.baseUrl2 + "/city/create-city";
  configEditCity = this.baseUrl2 + "/city/update-city";
  configDeleteCity = this.baseUrl2 + "/city/delete-city";
  importExcelCity = this.baseUrl2 + "/city/import-city";

  // Region
  configGetRegion = this.baseUrl2 + "/region/get-region?program_id=1";
  configAddRegion = this.baseUrl2 + "/region/create-region";
  configUpdateRegion = this.baseUrl2 + "/region/update-region";
  configDeleteRegion = this.baseUrl2 + "/region/delete-region";
  importExcelRegion = this.baseUrl2 + "/region/import-region";

  //firebase excel upload
  firebaseExcelUplad = this.baseUrl2 + "/reports-panel/import-firebase-report";


  //usermanagement
  configGetRoles = this.baseUrl2 + "/users/get-user-roles";
  usermanagementEntityList = this.baseUrl2 + "/users/get-member-type-list";


  //hubadmin



  //agent panel
  getBooking = this.baseUrl2 + "/reward/getbookings";
  changeBooking = this.baseUrl2 + "/reward/change-booking-status";
  getVanue = this.baseUrl2 + "/reward/getvenue"
  downloadBooking = this.baseUrl2 + "/reward/download-bookingdata"
  getQueries = this.baseUrl2 + "/queries/get-queries";
  updateQueries = this.baseUrl2 + "/queries/update-queries";
  getEmailTemplete = this.baseUrl2 + "/reward/get-privilege-template"
  agentDashboard = this.baseUrl2 + '/staff/get-agent-dashboard';
  getAgentCourseList = this.baseUrl2 + '/course/get-course-bookings';
  getCourseEmailTemplete = this.baseUrl2 + "/course/get-course-template";
  saveCourseBookingData = this.baseUrl2 + "/course/save-course-booking-status";
  saveCourseBookingWithTemplate = this.baseUrl2 + '/course/change-course-booking-status';
  courseBookingDataDownload = this.baseUrl2 + '/course/download-coursebookingdata'
  getProjectCategory = this.baseUrl2 + "/staff/project-category-list?program_id=1";
  getagentCategory = this.baseUrl2 + "/staff/category-list?program_id=1"
  getListInvoice = this.baseUrl2 + "/staff/list-invoice?program_id=1";
  getInvoiceStatusCount = this.baseUrl2 + "/staff/list-invoice-count?program_id=1"
  approveRejectInvoice = this.baseUrl2 + "/staff/change-invoice-status?program_id=1"
  editInvoice = this.baseUrl2 + "/staff/edit-invoice?program_id=1"



  //branch manager
  getUserEnrollmentcount = this.baseUrl2 + '/users/enrollmentcount?program_id=1';
  getUserEnrollmentList = this.baseUrl2 + '/users/enrollment-list?program_id=1';
  getSingleUserDetails = this.baseUrl2 + '/users/get-userbyid?';
  approveRejectEnroll = this.baseUrl2 + '/users/approve-reject-enrollment?program_id=1';
  downloadfilterEnrollexl = this.baseUrl2 + '/users/download-enrollment?program_id=1';

  //reporting pannel
  enrollmentReport = this.baseUrl2 + '/reports-panel/get-enrollment-report';
  engagementReport = this.baseUrl2 + '/reports-panel/get-engagement-report';
  privilegeReport = this.baseUrl2 + '/reports-panel/get-privilege-report';
  offerReport = this.baseUrl2 + '/reports-panel/get-offers-report';
  redumptionReport = this.baseUrl2 + '/reports-panel/get-redemption-report';
  queryReport = this.baseUrl2 + '/reports-panel/get-query-report';
  interestReport = this.baseUrl2 + '/reports-panel/get-interest-report';
  LoginReport = this.baseUrl2 + "/reports-panel/get-logged-in-report";
  firebaseReport = this.baseUrl2 + "/reports-panel/get-firebase-report"
  nonParticipateUser = this.baseUrl2 + "/reports-panel/app-not-downloaded-users";
  courseReport = this.baseUrl2 + '/reports-panel/get-course-report';
  invoiceReport = this.baseUrl2 + '/reports-panel/get-invoice-report';

  //Course Hubadmin
  getCourseList = this.baseUrl2 + "/course/getcourses";
  importCourseExcel = this.baseUrl2 + "/course/import-courses";





upload_user_point = this.baseUrl2 + "/reports-panel/upload-users-point-summary"






  // getEnrollments = this.baseUrl2 + '/visit-tracker/get-enrollments?program_id=1';
  // getReasons = this.baseUrl2 + '/visit-tracker/rejection-reasons?program_id=1';
  // bulkApprove = this.baseUrl2 + '/visit-tracker/bulk-verification'
  //users
  usersExcelImport = this.baseUrl2 + '/users/import-users'
  //whitelabel
  getDashBoardDetails =
    this.baseUrl2 + "/adminreport/generalreports?program_id=1&";
  //***MASTERDATA***/



  //Brands
  configGetBrands = this.baseUrl2 + "/brands/get-brand?program_id=1";
  configAddBrand = this.baseUrl2 + "/brands/create-brand";
  configEditBrand = this.baseUrl2 + "/brands/update-brand";
  configDeleteBrand = this.baseUrl2 + "/brands/delete-brand";
  //Channels
  configGetChannel = this.baseUrl2 + "/channels/get-channel?program_id=1";
  configAddChannel = this.baseUrl2 + "/channels/create-channel";
  configEditChannel = this.baseUrl2 + "/channels/update-channel";
  configDeleteChannel = this.baseUrl2 + "/channels/delete-channel";
  // Languages
  configGetLanguages = this.baseUrl2 + "/language/get-languages";
  configAddLanguage = this.baseUrl2 + "/language/create-language";
  configEditLanguage = this.baseUrl2 + "/language/update-language";
  configDeleteLanguage = this.baseUrl2 + "/channels/delete-channel";
  // Frequency
  configGetFrequency = this.baseUrl2 + "/frequency/get-frequency";
  configAddFrequency = this.baseUrl2 + "/frequency/create-frequency";
  configEditFrequency = this.baseUrl2 + "/frequency/update-frequency";
  // Units
  configGetUnit = this.baseUrl2 + "/units/get-units";
  configAddUnit = this.baseUrl2 + "/units/create-unit";
  configEditUnit = this.baseUrl2 + "/units/update-unit?id=";
  // Brand Categories
  configGetBrandCategory = this.baseUrl2 + "/brand-category/get-brand-categories?program_id=1";
  configAddBrandCategory =
    this.baseUrl2 + "/brand-category/create-brand-category";
  configUpdateBrandCategory =
    this.baseUrl2 + "/brand-category/update-brand-category"; //same for delete
  //ROLES
  configAddRoles = this.baseUrl2 + "/user-role/create-user-role";
  configUpdateRoles = this.baseUrl2 + "/user-role/update-user-role"; //same for delete
  //***USER MANAGEMENT***/
  allUsers = this.baseUrl2 + "/users/get-users";
  allProfiles = this.baseUrl2 + "/user-role/get-user-roles";
  createUserPost = this.baseUrl2 + "/users/create-user";
  updateUserPost = this.baseUrl2 + "/users/update-user?id=";
  importExcelUser = this.baseUrl2 + "/users/import-users";
  userRegistrationDetails = this.baseUrl2 + '/user-role/supervisors?'
  getUserById = this.baseUrl2 + "/users/get-users?id=";
  //Filter data, regions,channel,roles, geography
  filterDataParams = this.baseUrl2 + "/course/get-course?program_id=1";
  //GET OUTLETS MAPPED TO A TSE
  getMappedOutlets = this.baseUrl2 + "/visit-tracker/get-tse-outlets";
  //visit tracker
  getCampaingsBasedOnFilter = this.baseUrl2 + '/campaign/search-campaigns';
  getTasksBasedOnCampaign = this.baseUrl2 + '/visit-tracker/visit-tracker-tasks?program_id=1&campaign_id=';
  getVtTseList = this.baseUrl2 + '/visit-tracker/visit-tracker-tse?program_id=1&task_id=';
  //**Channel**//
  getAllChannel = this.baseUrl2 + "/channels/get-channel?program_id=1";
  //Reports
  enrollmentReports = this.baseUrl2 + '/outlet-reports/export-enrollment-users'
  twoTimeRejectedReport = this.baseUrl2 + "/outlet-reports/admin-rejected-report"
  getUsersByRole = this.baseUrl2 + '/outlet-reports/userby-region'
  engagementReports = this.baseUrl2 + '/outlet-reports/engagement-report';
  regionWiseReports = this.baseUrl2 + '/outlet-reports/export-region-wise-targets';
  targetsVsAchievedReports = this.baseUrl2 + '/outlet-reports/export-target-vs-achieved';
  regionWisePoints = this.baseUrl2 + '/outlet-reports/outlet-points';
  pointBalanceReport = this.baseUrl2 + '/outlet-reports/point-balance-report';
  redemptionReport = this.baseUrl2 + '/outlet-reports/redemption-report';
  topTenReport = this.baseUrl2 + '/outlet-reports/export-target-achieved-rank';
  outletWiseInvoiceReport = this.baseUrl2 + '/outlet-reports/export-outlet-wise-invoice-report';
  categoryWiseAchivementReport = this.baseUrl2 + '/outlet-reports/export-product-category-wise-achievement';
  downloadSelectedUsersList = this.baseUrl2 + '/users/download-userdata';
  ordersReport = this.baseUrl2 + '/outlet-reports/ecommerce-order-report';
  update_user = this.baseUrl2 + '/users/update-users';
  delete_user = this.baseUrl2 + '/users/delete-users';


  // Cumulative reports
  cumulativeEnrollmentReports = this.baseUrl2 + '/cumulative-reports/cumulative-enrollment-report'
  cumulativePointBalanceReport = this.baseUrl2 + '/cumulative-reports/point-balance-report';
  cumulativeOrdersReport = this.baseUrl2 + '/cumulative-reports/order-report';
  cumulativeEngagementReport = this.baseUrl2 + '/cumulative-reports/engagement-report'
  //***REWARDS***//

  //** Point Balance Report */

  //reward category
  getRewardCategories =
    this.baseUrl2 + "/rewards/get-reward-category?program_id=1";
  addRewardCategories = this.baseUrl2 + "/rewards/create-reward-category";
  editRewardCategories = this.baseUrl2 + "/rewards/update-reward-category";
  deleteRewardCategories = this.baseUrl2 + "/rewards/delete-reward-category";
  rewardHistory = this.baseUrl2 + "/rewards/get-reward-orders?program_id=1";
  getRewardProducts =
    this.baseUrl2 + "/rewards/get-reward-products?program_id=1";
  getRewardProductbyId =
    this.baseUrl2 + "/rewards/get-reward-products?program_id=1&id=";
  createRewardProducts = this.baseUrl2 + "/rewards/create-reward-products";
  updateRewardProducts = this.baseUrl2 + "/rewards/update-reward-products";
  getRewardSubCategory =
    this.baseUrl2 +
    "/rewards/get-reward-sub-categories?program_id=1&reward_category_id=";
  getRewardsBrands =
    this.baseUrl2 +
    "/rewards/get-reward-category-brands?program_id=1&reward_category_id=";

  //reward sub category
  getRewardSubCategories =
    this.baseUrl2 + "/rewards/get-reward-subcategory?program_id=1";
  addRewardSubCategories = this.baseUrl2 + "/rewards/create-reward-subcategory";
  editRewardSubCategories =
    this.baseUrl2 + "/rewards/update-reward-subcategory";
  deleteRewardSubCategories =
    this.baseUrl2 + "/rewards/delete-reward-subcategory";
  //Reward Type
  getRewardType = this.baseUrl2 + "/reward-type/get-reward-types";
  addRewardType = this.baseUrl2 + "/reward-type/create-reward-type?id=1";
  editRewardType = this.baseUrl2 + "/reward-type/update-reward-type?id=";
  deleteRewardType = this.baseUrl2 + "/reward-type/update-reward-type?id=";
  // Engagement API
  engagementCreateActivity =
    this.baseUrl2 + "/engagement/create-campaign-activity";
  engagementUpdate = this.baseUrl2 + "/engagement/update-task?id=";
  getAllEngagement = this.baseUrl2 + "/engagement/get-engagements";
  createEngagementTask = this.baseUrl2 + "/engagement/create-task";
  getUserAssignment = this.baseUrl2 + "/user-modules/get-all-whitelisted-user";
  getUsersFilter = this.baseUrl2 + "/users/search-users-by-filter";
  getQuizDetails = this.baseUrl2 + "/engagement/get-quiz-activities?";
  assignUserDetails = this.baseUrl2 + "/engagement/assign-user-task";
  createQuizEngage = this.baseUrl2 + "/engagement/create-quiz";
  updateQuizEngage = this.baseUrl2 + "/engagement/update-quiz?id=";
  createpointsConfig =
    this.baseUrl2 + "/engagement/create-engagement-point-structure";
  getEngagementTaskPoints =
    this.baseUrl2 + "/engagement/engagement-point-structure?task_id=";
  getAssignedUsers = this.baseUrl2 + "/engagement/get-user-tasks?task_id=";
  uploadImages = this.baseUrl2 + "/engagement/upload-img";

  adhoc = this.baseUrl2 + "/ops-panel/adhoc-userpoints";

  /**
   * Activity
   */
  campaign_activites = this.baseUrl2 + "/engagement/get-campaign-activites";
  createActivity = this.baseUrl2 + "/engagement/create-campaign-activity";
  updateActivity = this.baseUrl2 + "/engagement/update-campaign-activity?id=";
  getActivityBasedOnTask =
    this.baseUrl2 + "/engagement/get-campaign-activites?campaign_id=";
  //orders
  getAllOrders = this.baseUrl2 + "/ecommerce/get-ecommerce-orders";
  getOrderDetails = this.baseUrl2 + "/ecommerce/view-ecommerce-order?id=";
  orderApproveReject = this.baseUrl2 + "/ecommerce/accept-reject-order";
  getAllCategiry =
    this.baseUrl2 + "/ecommerce/get-ecommerce-categories?program_id=1";
  createCatgory = this.baseUrl2 + "/ecommerce/create-ecommerce-categories";
  // updateCategory = this.baseUrl2 + "/ecommerce/update-ecommerce-categories";

  //***REWARDS***//
  getAllOffers =
    this.baseUrl2 + "/ecommerce/loaddata-for-offer-details?program_id=1";
  //Schemes
  getSchemes = this.baseUrl2 + "/scheme/get-scheme";
  addSchemes = this.baseUrl2 + "/scheme/create-scheme-data";
  getSchemeConfig = this.baseUrl2 + "/scheme/get-scheme?id=";
  getSchemeProductCategory = this.baseUrl2 + "/scheme/get-brand-category";
  extendScheme = this.baseUrl2 + '/scheme/update-scheme-date?id=';
  getSchemeConfigSettings = this.baseUrl2 + '';
  updateScheme = this.baseUrl2 + '/scheme/update-scheme?id='
  //Visibility /get-posm-config
  getVisibilityList =
    this.baseUrl2 + "/visibility/get-posm-elements?program_id=1";
  addVisibility = this.baseUrl2 + "/visibility/create-posm-elements";
  editVisibility = this.baseUrl2 + "/visibility/update-posm-elements";
  deleteVisibility = this.baseUrl2 + "/visibility/delete-posm-elements";
  saveVisibilityConfig = this.baseUrl2 + "/visibility/create-posm-config";
  getVisibilityConfig =
    this.baseUrl2 + "/visibility/get-posm-config?program_id=1";
  updateVisibilityConfig = this.baseUrl2 + "/visibility/update-posm-config";
  getVisibilitySubmittedList =
    this.baseUrl2 +
    "/visibility/get-posm-audit-transactions?page=";
  visibilityApproveOrReject =
    this.baseUrl2 + "/visibility/posm-audit-approve-reject";
  getPosmOnChannel = this.baseUrl2 + '/visibility/get-channel-posm-elements?program_id=1&channel_id=';
  getShelfConditions = this.baseUrl2 + '/visibility/get-shelves-cooler-purity?program_id=1'
  getShelfConditionsForSubmission = this.baseUrl2 + '/visibility/get-shelves-points?program_id=1&posm_master_config=';
  //campaign
  getAllCampaign = this.baseUrl2 + "/campaign/get-campaigns";
  addCampaign = this.baseUrl2 + "/campaign/create-campaign";
  editCampaign = this.baseUrl2 + "/campaign/update-campaign";
  //* GEOGRAPHY *//
  getAllGeographies = this.baseUrl2 + "/geographical/get-geographs";
  addGeography = this.baseUrl2 + "/geographical/create-geography";
  editGeography = this.baseUrl2 + "/geographical/update-geography?id=";
  //ecommerce
  getProductsList = this.baseUrl2 + "/product/listallproducts?program_id=1";
  getProductById =
    this.baseUrl2 + "/product/editproduct?program_id=1&product_id=";
  getProductDelete = this.baseUrl2 + "/product/deleteproduct";
  addToProductList = this.baseUrl2 + "/product/addnewproduts";
  getProductCategories =
    this.baseUrl2 + "/ecommerce/get-ecommerce-categories?program_id=1";
  updateProduct = this.baseUrl2 + "/product/updateproduct";
  createGeography = this.baseUrl2 + "/geographical/create-geography";
  getProductVarient = this.baseUrl2 + "/product/get-product-variants";
  getProductVarientValues =
    this.baseUrl2 + "/product/get-product-variant-values?product_variant_id=";
  createOfferDetails = this.baseUrl2 + "/ecommerce/create-offer-details";
  getOfferDetails =
    this.baseUrl2 + "/ecommerce/get-offer-details?program_id=1&offer_id=";
  getEcommerceSubCategories =
    this.baseUrl2 +
    "/ecommerce/get-ecommerce-sub-categories?program_id=1&category_id=";
  //****carton Insert****//
  getCartonList = this.baseUrl2 + "/tertiary-programs/get-cartons?program_id=1";
  getCartonCodes =
    this.baseUrl2 +
    "/tertiary-programs/get-cartons-codes?program_id=1&carton_id=";
  createCarton = this.baseUrl2 + "/tertiary-programs/create-cartons";
  generateQRImages =
    this.baseUrl2 + "/tertiary-programs/qr-code-generation-api";
  /**
   * Content & learning
   */
  getContenentAndLearning =
    this.baseUrl2 + "/content-learning/get-course?program_id=1&course_id=";
  getTaskTypes = this.baseUrl2 + "/content-learning/course-type";
  courseCreation = this.baseUrl2 + "/content-learning/create-course";
  courseUserAssign = this.baseUrl2 + "/content-learning/map-course-users";
  updateContentAndLearning = this.baseUrl2 + "/content-learning/update-course";
  deleteContentAndLearning = this.baseUrl2 + "/content-learning/delete-course";
  createCourseQuiz = this.baseUrl2 + "/content-learning/create-quiz";
  updateCourseQuiz = this.baseUrl2 + "/content-learning/update-quiz";
  deleteCourseQuiz = this.baseUrl2 + "/content-learning/delete-quiz";
  getAllCourseQuiz =
    this.baseUrl2 + "/content-learning/get-quiz?program_id=1&course_id=";
  getContentAndLearningMappedUser =
    this.baseUrl2 + "/content-learning/mapped-course-user-lists";

  /**
   * Visit tracker
   */
  getVisits = this.baseUrl2 + "/visit-tracker/get-visits?program_id=1";
  createVisits = this.baseUrl2 + "/visit-tracker/create-visits";
  getVisitEnrollment =
    this.baseUrl2 + "/visit-tracker/get-enrollments?program_id=1";
  //approveRejectEnroll =this.baseUrl2 + "/visit-tracker/approve-reject-enrollment";
  saveScheme = this.baseUrl2 + "/scheme/create-scheme-data";
  createEnroll = this.baseUrl2 + "/visit-tracker/create-enrollment";
  getOfferById = this.baseUrl2 + "/ecommerce/edit-offers?program_id=1&";

  /****/
  getTargetAchivementList = this.baseUrl2 + "/loyalty-target/get-targets";
  updateTargetAchivement = this.baseUrl2 + "/loyalty-target/update-target";
  addTargetAchivement = this.baseUrl2 + "/loyalty-target/create-target";
  assignedLoyaltyTarget = this.baseUrl2 + "/loyalty-target/get-loyalty-targets";
  createOffer = this.baseUrl2 + "/ecommerce/create-offers";
  deleteOffer = this.baseUrl2 + "/ecommerce/delete-offers";
  updateOffer = this.baseUrl2 + "/ecommerce/update-offers";
  createWhitelist = this.baseUrl2 + "/user-modules/create-user-module";
  getModule = this.baseUrl2 + "/module/get-modules";
  getUserModules = this.baseUrl2 + "/user-modules/get-user-modules";
  createLoyalty = this.baseUrl2 + "/loyalty-target/add-target-point-structure";
  getAssignedUser = this.baseUrl2 + "/loyalty-target/get-assigned-users";
  getLoyaltyTargets = this.baseUrl2 + "/loyalty-target/get-loyalty-targets";
  getPointStructure = this.baseUrl2 + "/points-structure/get-points-structure";
  updateLoyaltyTarget = this.baseUrl2 + "/loyalty-target/update-loyalty-target";
  updatePointsTarget =
    this.baseUrl2 + "/points-structure/update-points-structure";
  /**Excel section */
  uploadMultiProducts = this.baseUrl2 + "/product/upload-products";
  uploadMultiRewards = this.baseUrl2 + "/rewards/create-reward-products-excel";
  createSalesAchivement = this.baseUrl2 + "/loyalty-target/create-achievements";
  getSalesAchivements =
    this.baseUrl2 + "/loyalty-target/get-loyalty-achievements";
  updateSalesAchievement =
    this.baseUrl2 + "/loyalty-target/update-loyalty-achievement";
  approveSalesAchievement =
    this.baseUrl2 + "/loyalty-target/approve-sales-achievements";
  filterByCity = this.baseUrl2 + '/city/filter-city';
  productUploadExcelFields = this.baseUrl2 + '/product/product-upload-fields';
  getReardProductsWithPagination = this.baseUrl2 + '/rewards/get-reward-products-filter?program_id=1';
  getAllLoyaltyTargetsMapping = this.baseUrl2 + '/loyalty-target/get-loyalty-target-general-mapping?target_id=';
  getAllParentRolls = this.baseUrl2 + '/user-role/parentroles';
  getAllModules = this.baseUrl2 + '/user-modules/get-modules-lists';
  getRollProfileList = this.baseUrl2 + '/user-role/profiles';
  getRollTypes = this.baseUrl2 + '/user-role/roletypes';
  getTargetListBasedOnPage = this.baseUrl2 + "/loyalty-target/get-loyalty-target-general-mapping?target_id=";
  getPointListBasedOnPage = this.baseUrl2 + "/loyalty-target/get-loyalty-point-general-mapping?target_id=";
  createBrandTargets_general = this.baseUrl2 + "/loyalty-target/create-loyalty-general-setting";
  getHierarchyLevel_roles = this.baseUrl2 + '/user-role/role-hierarchy';
  excel_upload_targetsDetails = this.baseUrl2 + '/loyalty-target/update-loyalty-targets';
  update_targets_for_sku = this.baseUrl2 + '/loyalty-target/update-loyalty-general-setting-with-products?id=';
  setTarget_points_for_sku = this.baseUrl2 + '/loyalty-target/create-loyalty-points-setting';
  createArchivements_excelUpload = this.baseUrl2 + "/loyalty-target/create-achievements";
  getArchivement_list = this.baseUrl2 + '/loyalty-target/get-achievements-list?program_id=1';
  generateCheckerAndMaker = this.baseUrl2 + "/loyalty-target/create-achievement-report";
  getGeneratedCheckerAndMaker = this.baseUrl2 + "/loyalty-target/get-achievement-report?program_id=1";
  getDownloadReport = this.baseUrl2 + "/loyalty-target/get-excel-achievement?program_id=1&";
  approveReport = this.baseUrl2 + "/loyalty-target/approve-reject-achievement";
  modifyCheckerAndMakerPoints = this.baseUrl2 + "/loyalty-target/maker-checker-transactions";
  brandsExcelHeaders = this.baseUrl2 + '/brands/brand-upload-fields';
  brandsExcelUpload = this.baseUrl2 + "/brands/upload-brands";
  deleteTarget = this.baseUrl2 + "/loyalty-target/delete-targets";
  getTargetPointBrands = this.baseUrl2 + "/brands/get-brand?program_id=1";
  getUserTargetPoints = this.baseUrl2 + "/loyalty-target/user-targets-points?target_id=";
  getUserTargets = this.baseUrl2 + "/loyalty-target/get-users-targets?target_id=";
  getUserPoints = this.baseUrl2 + "/loyalty-target/get-users-points?target_id=";
  getEngementDetailsForEdit = this.baseUrl2 + "/engagement/get-engagementbyid?eng_id=";
  getBrandCategoryHeaders = this.baseUrl2 + '/brand-category/excel-brand-category-headers';
  uploadExcelDataBrandCategory = this.baseUrl2 + "/brand-category/upload-brand-category";
  uploadExcelForTargets = this.baseUrl2 + "/loyalty-target/upload-target-points";
  downloadExcelForTargets = this.baseUrl2 + "/loyalty-target/get-excel-target-sku?program_id=1&target_id=";
  get_all_rewards_products = this.baseUrl2 + '/reward/productlisthome?program_id=1';


  // All invoice api here ..
  getAllInvoice = this.baseUrl2 + "/invoice/get-all-invoices";
  getApproveInvoice = this.baseUrl2 + "/invoice/get-approved-invoices";
  getRejectInvoice = this.baseUrl2 + "/invoice/get-rejected-invoices";
  getPendingInvoice = this.baseUrl2 + "/invoice/get-pending-invoices";
  getProgramListInvoice = this.baseUrl2 + "/invoice/get-program-list";
  updateInvoiceUrl = this.baseUrl2 + "/invoice/update-invoice";
  approveInvoiceUrl = this.baseUrl2 + "/invoice/approve-invoice";
  rejectInvoiceUrl = this.baseUrl2 + "/invoice/reject-invoice";
  delProdInvoiceUrl = this.baseUrl2 + "/invoice/delete-invoice-product";
  srcInvoiceUrl = this.baseUrl2 + "/invoice/search-invoices";
  getOrderInvoiceUrl = this.baseUrl2 + "/invoice/get-invoice-orders";


  // Staff & Agent Panel
  staff_Dashboard = this.baseUrl2 + "/staff/team-leader-dashboard"
  staff_invoce_trasection = this.baseUrl2 + "/staff/invoice-bulkassignlist";
  staff_enrollment = this.baseUrl2 + "/staff/entrollement-bulkassignlist";
  staff_unassign_count = this.baseUrl2 + "/staff/get-unassigned-invoice";
  engagement_unassign = this.baseUrl2 + "/staff/get-unassigned-engagement";
  enrollment_unassign = this.baseUrl2 + "/staff/get-unassigned-entrollement";
  agent_enrollment = this.baseUrl2 + "/staff/get-enrollement-list";



  // All ops-panel api here ..
  getRewardDropdown = this.baseUrl2 + "/ops-panel/get-rewards-dropdown";
  getOrderList = this.baseUrl2 + "/ops-panel/get-order-listings";
  getDownOrderData = this.baseUrl2 + "/ops-panel/download-order-data";
  orderOfferCodeUp = this.baseUrl2 + "/ops-panel/order-offer-code-upload";
  orderOfferReject = this.baseUrl2 + "/ops-panel/reject-orders";
  resendOfferCode = this.baseUrl2 + "/ops-panel/resend-offercode-sms";


  getStaffEnagmentDashboard = this.baseUrl2 + "/staff/team-leader-enagement-dashboard";
  getStaffAllAgentDashboard = this.baseUrl2 + "/staff/pitcurebulkassignlist";
  PICTURE = this.baseUrl2 + '/staff/get-engagement-transactions';
  bulkAssignEnagement = this.baseUrl2 + '/staff/bulkassign';
  PICTURE_TEMPLATE = this.baseUrl2 + '/staff/get-picture-templates';
  PICTURE_APPROVE = this.baseUrl2 + '/staff/approvepicture';
  PICTURE_REJECT = this.baseUrl2 + '/staff/rejectpicture';
  agentInvoice = this.baseUrl2 + '/staff/get-invoice-transactions';
  agentApprovedInvoice = this.baseUrl2 + '/staff/get-approved-invoices-by-id';
  agentRejectedInvoice = this.baseUrl2 + '/staff/get-rejected-invoices-by-id';
  agentPendingInvoice = this.baseUrl2 + '/staff/get-pending-invoices-by-id';
  /**
   * Communication Matrix
   */
  get_basic_communication_matrix = this.baseUrl2 + '/communication-panel/get-communication-matrix';
  get_matrix_module_details = this.baseUrl2 + '/communication-panel/get-scenario-details?';
  update_communication_matrix = this.baseUrl2 + '/communication-panel/update-scenario-details';
  send_announcement = this.baseUrl2 + "/communication-panel/create-custom-notifications";

  // All ops-panel api of customer report here ..
  getUsrPointLedger = this.baseUrl2 + "/ops-panel/user-points-ledger";
  getDownPointLedger = this.baseUrl2 + "/ops-panel/download-points-ledger";

  // All ops-panel api of points passbook here ..
  getUsrPassbook = this.baseUrl2 + "/ops-panel/points-passbook";
  getDownPassbook = this.baseUrl2 + "/ops-panel/download-passbook";

  uploadOrderDetails = this.baseUrl2 + "/ecommerce/import-order-details";

  checker_maker_bonous_points = this.baseUrl2 + '/loyalty-target/get-bonous?';

  create_communication_matrix = this.baseUrl2 + '/communication-panel/create-scenario';
  get_beats = this.baseUrl2 + "/beats/get-beats";
  create_beat = this.baseUrl2 + "/beats/create-beats";
  update_beat = this.baseUrl2 + "/beats/update-beats?id=";
  delete_beat = this.baseUrl2 + "/beats/delete-beats?id=";

  enrollmentListing = this.baseUrl2 + "/visit-tracker/get-enrollments"
  getEnrollmentCount = this.baseUrl2 + "/visit-tracker/get-enrollments-count";
  getStatus = this.baseUrl2 + "/visit-tracker/get-status-list";
  getSegment = this.baseUrl2 + "/visit-tracker/get-segment-list";
  enrollmetRejectedResons = this.baseUrl2 + "/visit-tracker/get-verification-reason-list";
  enrollmentApprovedRejectedOutlet = this.baseUrl2 + "/visit-tracker/approve-reject-document";
  getenrollmentEntry = this.baseUrl2 + "/visit-tracker/get-entity-list";
  enrollmentExcelExport = this.baseUrl2 + "/visit-tracker/get-enrollment-excel";
  userManagementEnrollUser = this.baseUrl2 + "/users/import-ctply-users";
  selectCycle = this.baseUrl2 + "/visit-tracker/get-cycle-list";
  enrollmentExcelUplaod = this.baseUrl2 + "/visit-tracker/upload-enrollment-excel";
  uploadTargetExcel = this.baseUrl2 + "/loyalty-target/upload-outlet-target-points";
  targetTableData = this.baseUrl2 + "/loyalty-target/get-outlet-target-points";
  deleteTargetListData = this.baseUrl2 + "/loyalty-target/delete-outlet-target-points";
  targetExelTemplete = this.baseUrl2 + "/loyalty-target/get-outlet-target-points-excel";
  getTargetOutlet = this.baseUrl2 + "/loyalty-target/get-cycle-outlets";
  getTargetCategory = this.baseUrl2 + "/loyalty-target/get-category-list";
  getTargetSku = this.baseUrl2 + "/loyalty-target/get-category-sku-list";
  addTarget = this.baseUrl2 + "/loyalty-target/add-outlet-target-points";
  editTarget = this.baseUrl2 + "/loyalty-target/edit-outlet-target-points";
  uploadAchievementData = this.baseUrl2 + "/loyalty-target/upload-outlet-target-achievement";
  downloadAchievementReport = this.baseUrl2 + "/loyalty-target/get-outlet-target-achievement-excel";
  getAchievementData = this.baseUrl2 + "/loyalty-target/get-outlet-target-achievement";
  deleteAchievement = this.baseUrl2 + "/loyalty-target/delete-outlet-target-achievement";
  editAchievement = this.baseUrl2 + "/loyalty-target/edit-outlet-target-achievement";
  addAchievement = this.baseUrl2 + "/loyalty-target/add-outlet-target-achievement";
  createAchievement = this.baseUrl2 + "/loyalty-target/create-achievement-report";
  getAchievementReport = this.baseUrl2 + "/loyalty-target/get-achievement-report";
  downloadAchivedExcwl = this.baseUrl2 + "/loyalty-target/get-excel-achievement";
  approveRejectAchievement = this.baseUrl2 + "/loyalty-target/approve-reject-achievement";
  whiteListingCycle = this.baseUrl2 + "/white-listing/get-outlet-cycle-whitelist";
  whitelistingExcelDownload = this.baseUrl2 + "/white-listing/get-outlet-cycle-whitelist-excel";
  whiteListanebleDeseble = this.baseUrl2 + "/white-listing/toggle-outlet-cycle-whitelist";
  whiteListingUploadExcel = this.baseUrl2 + "/white-listing/upload-outlet-cycle-whitelist";
  cycleList = this.baseUrl2 + "/loyalty-target/get-cycle";
  createCycle = this.baseUrl2 + "/loyalty-target/create-cycle";
  updateCycle = this.baseUrl2 + "/loyalty-target/update-cycle";
  deleteCycle = this.baseUrl2 + "/loyalty-target/delete-cycle";

  cashbackStatus = this.baseUrl2 + "/cashback/get-cashback-status-types";
  cashbackPayment = this.baseUrl2 + "/cashback/get-cashback-payment-types";
  cashbackListing = this.baseUrl2 + "/cashback/get-cashback-list";
  cashbackExcelLink = this.baseUrl2 + "/cashback/export-cashback-list";
  cashbackReport = this.baseUrl2 + "/cashback/get-cashback-report";
  cashbackReportExcel = this.baseUrl2 + "/cashback/export-cashback-report";
  cashbackBulkApprov = this.baseUrl2 + "/cashback/bulk-approve-cashback-list";
  cashbackReportExcelImport = this.baseUrl2 + "/cashback/import-cashback-report";
  makercheckeruploadExcel = this.baseUrl2 + "/loyalty-target/maker-checker-transactions";
  saveBookingData = this.baseUrl2 + "/reward/save-booking-status";


  //Reporing pannel Api
  dashboard = this.baseUrl2 + "/dashboard/get-general-dashboard"
  consolidatedreport = this.baseUrl2 + "/enrollmentreport/get-consolidatedsearched-report";
  payoutSummaryReport = this.baseUrl2 + "/reports-panel/get-payout-summary-report";
  TargetAndAchievement = this.baseUrl2 + "/reports-panel/get-target-achievement-report";
  outletStockReport = this.baseUrl2 + "/reports-panel/get-outlet-stock-report";
  KycReport = this.baseUrl2 + "/reports-panel/get-kyc-validation-reports";
  outletStockVerience = this.baseUrl2 + "/reports-panel/get-outlet-stock-variance-report"
  authorisationReport = this.baseUrl2 + "/reports-panel/get-user-authorization-report";
  kycConsolidatedReport = this.baseUrl2 + "/reports-panel/get-kyc-consolidated-validation-reports";
  getfirebaseStatistics = this.baseUrl2 + "/dashboard/get-clicked-details?program_id=1";
  // **************
}
export interface ApiConfigurationInterface { }





